import React from "react";

import {
  AppleIcon,
  PlayStoreIcon,
  SomosLogo,
} from "../../../assets/custom-icons";
import twitterIcon from "../../../assets/twitterIcon.svg";
import telegramIcon from "../../../assets/telegramIcon.svg";
import facebookIcon from "../../../assets/facebookIcon.svg";
import instaIcon from "../../../assets/instaIcon.svg";
import { Link } from "react-router-dom";
const Section6 = ({ innerWidth }) => {
  const scrollTo = (id_string) => {
    const element = document.querySelector(`#${id_string}`);
    element.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div
        className="w-full  min-h-[700px] md:max-h-[660px] relative  rounded-tl-[65px]   rounded-tr-[65px] login-bg-1 showcase "
        >
          <div className="video-container">

          <video autoPlay loop muted playsInline >
            <source src='https://somos-frontend-file.s3.amazonaws.com/Webapp/landing-bg.mov' type="video/mp4"/>
          </video>
          
            </div>
        <div className=" pt-[130px] z-[1] flex-col justify-center items-center gap-[45px] flex">
          <div className="w-full z-[1] lg:w-[782px] text-center text-zinc-100 text-[30px] lg:text-[50px] font-normal bebas-neue-regular">
            Join the world's first
            <br />
             crypto subscription plan today
          </div>
          <div className="justify-center items-center gap-8 flex  flex-col lg:flex-row">
          <div className="border-wrap rounded-full">
            <a
              href="https://play.google.com/store/apps/details?id=com.mindzita.somosblockchain"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="px-8 py-4 button-pos rounded-[32px] module justify-center items-center gap-2.5 flex">
                <PlayStoreIcon className="w-8 h-8 relative" />
                <div className="text-zinc-300 text-xl md:text-2xl font-medium font-Urbanist">
                  Get it on Google Play
                </div>
              </button>
            </a>
          </div>

          <div className="border-wrap rounded-full">
            <a
              href="https://apps.apple.com/us/app/somos-blockchainapp/id6477697163"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="px-8 py-4 button-pos rounded-[32px] module justify-center items-center gap-2.5 flex">
                <AppleIcon className="w-8 h-8 relative" />
                <div className="text-zinc-300 text-xl md:text-2xl font-medium font-Urbanist">
                  Download on the App Store
                </div>
              </button>
            </a>
          </div>

          </div>
        </div>
        <div className="flex justify-center items-center mt-48 md:mt-20">
          <div className=" w-full lg:w-[90%] 2xl:w-[1240px] px-8 lg:px-3  absolute lg:pt-64 2xl:pt-52  justify-between items-center gap-4 lg:gap-0 flex ">
            <SomosLogo />
            <div className="justify-end items-center gap-4 md:gap-10 flex">
            <Link
                to="/privacy-policy"
                className="text-zinc-300 text-[12px] md:text-lg font-medium button-pos">
                Privacy Policy
              </Link>
              <Link
                to="/terms-&-conditions"
                className="text-zinc-300 text-[12px] md:text-lg font-medium button-pos">
                Terms & Conditions
              </Link>
              <button
                className="text-zinc-300 text-[12px] md:text-lg font-medium button-pos"
                onClick={() => scrollTo("about")}>
                About
              </button>
              <button
                className="text-zinc-300 text-[12px] md:text-lg font-medium button-pos "
                onClick={() => scrollTo("services")}>
                Services
              </button>
              {/* <button
                className="text-zinc-300 text-[12px] md:text-lg font-medium button-pos"
                onClick={() => scrollTo("plan")}>
                Plan
              </button> */}
            </div>
          </div>
          <div className="w-full lg:w-[90%] 2xl:w-[1240px] pl-8 pr-4 lg:pr-0 lg:pl-3   gap-4 lg:gap-0 pt-[172px] lg:pt-[380px] absolute justify-between items-center  flex flex-col sm:flex-row">
            {innerWidth >= 640 && (
              <div className="opacity-50 text-center text-zinc-100 text-base font-medium  ">
                Somos blockchain. All rights reserved 2024
              </div>
            )}
            <div className="justify-center items-center flex gap-5">
              <div className=" justify-center items-start inline-flex">
                <div className="w-[72px]  px-8 flex-col justify-start items-start inline-flex">
                  <div className=" h-10 p-3 bg-icons justify-center items-center inline-flex">
                    <div className="w-4 flex-col justify-start items-start inline-flex">
                      <div className="w-4 h-4 flex-col justify-center items-center flex">
                        <div className="w-4 h-4 relative">
                          <img
                            src={twitterIcon}
                            className="w-[15.24px] h-[15.24px] left-[0.38px] top-[0.38px] absolute"
                            alt="ok"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-[72px]  px-8 flex-col justify-start items-start inline-flex">
                  <div className=" h-10 p-3  bg-icons justify-center items-center inline-flex">
                    <div className="w-4 flex-col justify-start items-start inline-flex">
                      <div className="w-4 h-4 flex-col justify-center items-center flex">
                        <div className="w-4 h-4 relative">
                          <div className="w-[15.24px] h-[15.24px] left-[0.38px] top-[0.38px] absolute">
                            <img
                              src={instaIcon}
                              className="w-[15.24px] h-[15.24px] left-[0.38px] top-[0.38px] absolute"
                              alt="ok"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {innerWidth <= 640 && (
              <div className="opacity-50 text-center text-zinc-100 text-base font-medium  ">
                Somos blockchain. All rights reserved 2024
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Section6;
