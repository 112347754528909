import React from "react";
import PlusIcon from "../../../assets/plusIcon.svg";
import CirclesIcon from "../../../assets/circleIcon.png";
const Section5 = () => {
  const dataComingSoon = [
    {
      content: "Innovating future",
      hoverText: "Giving back to the future bright minds all over the world",
    },
    {
      content: "Membership free",
      hoverText:
        "Not required to have a membership to apply for the scholarship",
    },
  ];

  return (
    <>

      <div className="relative">
        <div
          className=" h-[400px]    absolute"
          style={{
            // borderRadius: "386px",
            background: "rgba(254, 144, 82, 0.25)",
            filter: "blur(92px)",
            left: "50%",
            // transform: "translateX(90%)",
            right: 0,
            marginTop: "280px",
          }}></div>
      </div>
      <div className="w-full justify-center items-center gap-20 flex lg:my-16 relative overflow-hidden py-6 lg:py-0">
        <div className="w-full lg:w-fit  flex-col justify-start items-start gap-8 inline-flex pl-6 lg:pl-0">
          <div className="w-full lg:w-[551px] md:pl-20">
            <span className="text-zinc-100  text-[40px] lg:text-[70px] font-normal bebas-neue-regular">
              Somos Blockchain Scholarship{" "}
            </span>
            <span className="gradient-text text-[15px] md:text-[22px] font-medium  ">
              (coming soon)
            </span>
          </div>
          <div className="w-full flex-col justify-start items-start gap-[11px] flex  md:pl-24">
            {dataComingSoon.map((item, index) => (
              <div
                key={index}
                className="w-full lg:w-[551px] min-h-[110px] justify-center z-[1] items-center inline-flex ">
                <div
                  key={index}
                  className="w-full lg:w-[551px] h-[120px] justify-center items-center inline-flex">
                  <div className="w-full lg:w-[551px] self-stretch px-6 lg:px-3  flex-col justify-center items-start gap-6 inline-flex">
                    <div className="btn w-full btn-primary">
                      <span
                        className="btn_text w-full"
                        data-text={item.hoverText}>
                        <div className="w-full  justify-between items-center gap-5 flex ">
                          <div className="text-center text-stone-300 text-[24px] lg:text-[40px] font-medium  ">
                            {item.content}
                          </div>
                          <div className="p-2 bg-zinc-300 rounded-xl justify-center items-center gap-2.5 flex">
                            <img
                              src={PlusIcon}
                              alt="ok"
                              className="w-8 h-8 relative"
                            />
                          </div>
                        </div>
                      </span>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-full"
                      width="551"
                      height="2"
                      viewBox="0 0 551 2"
                      fill="none">
                      <path d="M0 1H551" stroke="white" strokeOpacity="0.2" />
                    </svg>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="md:pl-32 z-[1]">
            <button className="px-8 py-4 bg-gradient-to-b from-indigo-500 to-cyan-400 rounded-[32px] justify-center items-center gap-2.5 inline-flex">
              <div className="text-zinc-100 text-xl font-semibold  ">
                Join the waitlist
              </div>
            </button>
          </div>
        </div>
        <div className="absolute lg:static right-[-205px] bottom-[-224px]">
          <img src={CirclesIcon} alt="ok" />
        </div>
      </div>
    </>
  );
};

export default Section5;
