import React, { useEffect, useState } from 'react';

const PayPalButton = ({ amount, idSuffix, userid }) => {
  const [userId, setUserId] = useState(userid);
  const [transactionDetails, setTransactionDetails] = useState(null); // To hold transaction data for the success message 

  useEffect(() => {
    if (window.paypal) {
      // Pass the userId in the transaction data
      window.paypal.Buttons({
        fundingSource: window.paypal.FUNDING.PAYPAL, // Only show PayPal as a funding source
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: amount,
                },
                custom_id: userId,  // Attach the Ethereum wallet ID as custom_id
                description: `User Ethereum Wallet ID: ${userId}`,  // Or you can pass it in the description
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            console.log('Transaction details:', details);
            setTransactionDetails({
              message: 'Congratulations!Your payment is Successful!',
              transactionId: details.id, // Capture the transaction ID from PayPal response
            });
          });
        },
        onError: (err) => {
          alert('An error occurred during the payment process.');
        },
      }).render(`#paypal-button-container-${idSuffix}`);
    }
  }, [amount, idSuffix, userId]);

  useEffect(() => {
    console.log("PayPalButton rendered:", { amount, idSuffix, userid });
  }, [amount, idSuffix, userid]);

  return (
    <div id={`paypal-button-container-${idSuffix}`} style={{ display: 'block', width: '100%' }} >

      {/* Display the success message and transaction ID */}
      {transactionDetails && (
        <div
          style={{
            marginTop: '20px',
            padding: '20px',
            backgroundColor: '#4caf50',
            color: 'white',
            borderRadius: '8px',
            textAlign: 'center',
            fontSize: '18px',
            fontWeight: 'bold',
          }}
        >
          <p>{transactionDetails.message}</p>
          <p>Transaction ID: {transactionDetails.transactionId}</p>
        </div>
      )}
    </div>
  );
};

export default PayPalButton;
