import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useSpring, animated } from '@react-spring/web';
import PayPalButton from '../../../components/PayPalButton'; // Update path if necessary

const Section4 = ({ innerWidth, settings }) => {
  const ref = useRef();
  const [selectedPlan, setSelectedPlan] = useState(null); // State to track the selected plan
  const [activeSlideIndex, setActiveSlideIndex] = useState(0); // Track active slide

  // Animations for cards
  const [leftCardStyles, leftCardApi] = useSpring(() => ({
    transform: 'translateX(60%) rotate(-15deg)',
    x: 120,
    opacity: 0,
    config: { duration: 1000 },
  }));
  const [middleCardStyles, middleCardApi] = useSpring(() => ({
    opacity: 0,
    config: { duration: 1000 },
  }));
  const [rightCardStyles, rightCardApi] = useSpring(() => ({
    transform: 'translateX(-60%) rotate(15deg)',
    x: -120,
    opacity: 0,
    config: { duration: 1000 },
  }));

  // Track active slide for conditional rendering
  const handleBeforeChange = (oldIndex, newIndex) => {
    setActiveSlideIndex(newIndex);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const userId = queryParams.get('userid'); // Extract the user ID from URL

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            leftCardApi.start({
              transform: 'translateX(60%) rotate(0deg)',
              x: -280,
              opacity: 1,
              zIndex: '1',
            });
            middleCardApi.start({ opacity: 1 });
            rightCardApi.start({
              transform: 'translateX(-60%) rotate(0deg)',
              x: 280,
              opacity: 1,
              zIndex: '1',
            });
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [leftCardApi, middleCardApi, rightCardApi]);

  const sliderSettings = {
    ...settings,
    beforeChange: handleBeforeChange, // Track active slide
    infinite: false, // Prevent cloning of slides
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const plans = [
    { name: 'Plan 1', price: 24.99, description: 'Receives a minimum of 80% of the monthly membership in crypto monthly', idSuffix: 1 },
    { name: 'Plan 2', price: 9.99, description: 'Receives a minimum of 70% of the monthly membership in crypto monthly', idSuffix: 2 },
    { name: 'Plan 3', price: 49.99, description: 'Receives a minimum of 90% of the monthly membership in crypto monthly', idSuffix: 3 },
  ];

  return (
    <>
      <div
        id="plan"
        className={`w-full ${innerWidth <= 1025 ? 'min-h-[600px]' : 'min-h-[1000px]'} lg:min-h-[800px] relative bg-neutral-950 rounded-[65px]`}
      >
        <div
          className="w-full max-w-[400px] h-[400px] absolute"
          style={{
            borderRadius: '386px',
            background: 'rgba(254, 144, 82, 0.25)',
            filter: 'blur(100px)',
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: '90px',
          }}
        ></div>
        <div className="pt-16 justify-center items-center gap-2.5 ">
          <div className="text-center text-zinc-100 text-[50px] lg:text-[80px] font-normal bebas-neue-regular">
            Choose your plan
          </div>
        </div>
        {innerWidth <= 1280 ? (
          <>
            <Slider {...sliderSettings}>
              {plans.map((plan, index) => (
                <div
                  key={plan.idSuffix}
                  className="w-full max-w-[333px] gradient-box h-[400px] lg:h-[430px] 2xl:h-[460px] p-6 bg-[#151313] rounded-[32px] flex flex-col justify-between items-center gap-10"
                >
                  <div className="text-zinc-100 text-5xl font-medium">{plan.name}</div>
                  <div>
                    <span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold">
                      ${plan.price}
                    </span>
                    <span className="text-indigo-500 text-2xl font-semibold">/month</span>
                  </div>
                  <div className="text-center text-zinc-300 px-4 text-[15px] lg:text-xl font-medium">
                    {plan.description}
                  </div>
                  {/* Render PayPalButton only for active slide */}
                  {index === activeSlideIndex && (
                    <div className="border-wrap rounded-full w-full flex justify-center">
                      <PayPalButton amount={plan.price} idSuffix={plan.idSuffix} userid={userId} />
                    </div>
                  )}
                </div>
              ))}
            </Slider>
            <div className="text-[#FF7D05] text-center text-[14px] py-12 px-4 md:px-0 xl:py-0 md:text-lg font-medium">
              All deposits are made within 15 days of membership start date
            </div>
          </>
        ) : (
          <div ref={ref} className="w-full flex xl:flex-row justify-center items-center my-16 pb-[30px] lg:pb-0 px-8 lg:px-0">
            {plans.map((plan, index) => (
              <animated.div
                key={plan.idSuffix}
                style={
                  index === 0
                    ? leftCardStyles
                    : index === 1
                    ? middleCardStyles
                    : rightCardStyles
                }
                className="w-full max-w-[394px] gradient-box h-[400px] lg:h-[430px] 2xl:h-[460px] p-6 bg-[#151313] rounded-[32px] flex flex-col justify-between items-center gap-6"
              >
                <div className="text-zinc-100 text-5xl font-medium">{plan.name}</div>
                <div>
                  <span className="gradient-text-price text-[50px] xl:text-[80px] font-semibold">${plan.price}</span>
                  <span className="text-indigo-500 text-2xl font-semibold">/month</span>
                </div>
                <div className="text-center text-zinc-300 px-4 text-[15px] lg:text-xl font-medium">
                  {plan.description}
                </div>
                <div className="w-full flex justify-center mt-4">
                  <PayPalButton amount={plan.price} idSuffix={plan.idSuffix} userid={userId} />
                </div>
              </animated.div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Section4;
