import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { SomosLogo } from "../assets/custom-icons";
import ReusableMenu from "./ReusableMenu";

export default function MainNavigation() {
  const [open, setState] = useState(false);
  const scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Optional: Smooth scrolling effect
    });
  }
  const toggleDrawer = (open) => {
    setState(open);
  };
  const scrollTo = id_string => { 
    const element = document.querySelector(`#${id_string}`);
    element.scrollIntoView({ behavior: "smooth" });
   }
   useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && open) {
        setState(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [open]);
  const [btnText,setBtnText]=useState("English")
  return (
    <div className="flex justify-between w-full px-8  lg:px-20 2xl:mb-12"
    >

         <div className="z-1" onClick={scrollToTop}>
           <SomosLogo className="" />
         </div>


           <div className="">
           
             <ReusableMenu
               // isLib={true}
               // buttonIcon={arrowDown}
               buttonText={btnText}
               // handleChangeFun={filterDocuments}
               handleChangeFun={setBtnText}
               menuItems={[
                 { text: "English" },
                 { text: "French" },
                 { text: "Spanish" },
               ]}
             />
           </div>

   </div>
  );
}
